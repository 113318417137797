.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.activee{

  background-color: #f0f1f3;
  border-right: 1px solid #042954;;
}

span{
  color: #9ea8b5;
}
.activee span{
  color: #042954;
}
input[type="checkbox"]:checked ~ span {
  background-color: red; 
 }

 td{
  word-break:break;
  
}
.colr{
  font-size:18px;
  background-color:#ffa801;
  border-color:#042954;
}
.colr:hover{
  background-color: #042954;
  border-color:#ffa801;
}
.max-wdt{
  min-width: 515px !important;
}

.large{
  max-width: 40% !important;
}
.large-content{
  padding: 0 20px !important;
  /* margin: 20% 20%; */
}

/* for chart */

/* end of chart */

@media (min-width: 768px) and (max-width: 1024px){
  .shown{
    overflow: break-word;
    max-width: 100px; 
    font-size: 14px;
}
.change{
  font-size: 10px;
}
  .nav-link{
    font-size: 16px;
  }
}


/* trial */
.svg-item {
  width: 100%;
  font-size: 16px;
  margin: 0 auto;
  animation: donutfade 1s;
}

@keyframes donutfade {
/* this applies to the whole svg item wrapper */
  0% {
      opacity: .2;
  }
  100% {
      opacity: 1;
  }
}

@media (min-width: 992px) {
  .svg-item {
      width: 80%;
  }
}

.donut-ring {
  stroke: #EBEBEB;
}

.donut-segment {
  transform-origin: center;
  stroke: #FF6200;
}

.donut-segment-2 {
  stroke: aqua;
  animation: donut1 3s;
}

.donut-segment-3 {
  stroke: #d9e021;
  animation: donut2 3s;
}

.donut-segment-4 {
  stroke: #ed1e79;
  animation: donut3 3s;
}

.segment-1{fill:#ccc;}
.segment-2{fill:aqua;}
.segment-3{fill:#d9e021;}
.segment-4{fill:#ed1e79;}

.donut-percent {
  animation: donutfadelong 1s;
}

@keyframes donutfadelong {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes donut1 {
  0% {
      stroke-dasharray: 0, 100;
  }
  100% {
      stroke-dasharray: 69, 31;
  }
}

@keyframes donut2 {
  0% {
      stroke-dasharray: 0, 100;
  }
  100% {
      stroke-dasharray: 30, 70;
  }
}

@keyframes donut3{
  0% {
      stroke-dasharray: 0, 100;
  }
  100% {
      stroke-dasharray: 1, 99;
  }
}

.donut-text {
  font-family: Arial, Helvetica, sans-serif;
  fill: #FF6200;
}
.donut-text-1 {
  fill: aqua;
}
.donut-text-2 {
  fill: #d9e021;
}
.donut-text-3 {
  fill: #ed1e79;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 700;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: bold;
}

.donut-data {
  font-size: 0.12em;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
  color:#666;
  fill: #666;
  animation: donutfadelong 1s;
}




/* ---------- */
/* just for this presentation */
html { text-align:center; }
.svg-item {
max-width:30%;
display:inline-block;
}


@media (min-width: 1024px) and (max-width: 1366px){
  .showw{
    overflow: break-word;
}

}